<template>
  <div class="page-container">
    <!--新闻头部-->
    <div class="news-header" v-if="newsManage.bannerManage.type==='video'||newsManage.bannerManage.type==='img'">
      <!--视频模式-->
      <div class="video-model" v-if="newsManage.bannerManage.type==='video'">
        <video id="v2" autoplay="autoplay" loop="loop" preload="" muted="muted"
               x-webkit-airplay="true" airplay="allow" webkit-playsinline="true" playsinline="true"
               s draggable="true"
               class="video2 video"></video>
      </div>
      <!--图片模式-->
      <div class="image-model" v-if="newsManage.bannerManage.type==='img'"></div>
    </div>
    <div class="bg-world" v-if="newsManage.bannerManage.type==='world'">
      <div class="world">
        <div class="world-bg"
             style="background-image: url('http://resouce.cdzyhd.com/a03438e6-8911-4b8d-91e2-efd0669fd557.jpg'); transform: scale3d(1, 1, 1);"></div>
        <div class="world-globe" style="transform: scale3d(1, 1, 1);">
          <div class="world-globe-pole" style="display: block;"></div>
          <div class="world-globe-doms-container">
          </div>
          <div class="world-globe-halo"
               style="background-image: url('http://resouce.cdzyhd.com/b07a37f9-4993-4a2a-8a9b-c3fa30ababb9.png'); display: block;"></div>
        </div>
        <div class="text">
          <div>新闻中心</div>
          <div class="english">News Center</div>
        </div>
      </div>
    </div>
    <!--新闻列表-->
    <div class="news-list">
      <div class="li flex flex-between" v-for="news in lists.list" @click="ListMethods().clickOneNews(news)">
        <div class="li-left flex flex-between">
          <img class="head-img" :src="news.headImgUrl"
               alt="">
          <div class="info">
            <h1 class="title">{{ news.title }}</h1>
            <h3 class="description">
              {{ news.description }}
            </h3>
          </div>
        </div>
        <div class="li-right flex flex-dr flex-center">
          <div class="day">{{ date_format(news.createTime, "dd") }}</div>
          <div class="year-month">{{ date_format(news.createTime, "yyyy-MM") }}</div>
        </div>
      </div>
    </div>
    <!--新闻分页-->
    <div class="news-page-tool">
      <el-pagination
          background
          layout="prev, pager, next"
          @current-change="(number)=>ListMethods().pageChange(number)"
          :current-page.sync="lists.pages.number" :page-size.sync="lists.pages.size"
          :total="lists.pages.totalElements"
          @size-change="(size)=>ListMethods().pageLimitChange(size)"
          :page-count="lists.pages.totalPages"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {ConfigModel} from "../../model/erp/ConfigModel";
import {OfficialWebNewsModel} from "../../model/erp/OfficialWebNewsModel";
import {date_format} from "../../utils/common";
import $ from "jquery";
import 'objectFitPolyfill';

export default {
  name: "newsList",
  data() {
    return {
      date_format: date_format,
      // 配置
      newsManage: {
        bannerManage: {}
      },
      // 列表
      lists: {
        list: [],
        loading: false,
        query: {},
        pages: {
          size: 10
        },
      },
    }
  },
  async mounted() {
    $("#header-container .navs .news").addClass("active") // 顶部导航增加active
    // 获取新闻页配置
    let newsManage = await ConfigModel.getNewsConfig()
    this.$set(this, "newsManage", newsManage);
    // 开始渲染
    this.$nextTick(() => {
      if(newsManage.bannerManage.type==='world'){
        // 绘制地球
        let css1 = document.createElement('link');
        css1.rel = 'stylesheet';
        css1.type = "text/css"
        css1.href = 'news/world.css';
        document.body.append(css1);
        css1.addEventListener('load', e => {
          let script1 = document.createElement('script');
          script1.id = 'dataGui';
          script1.src = 'news/dat.gui.min.js';
          document.body.append(script1);
          script1.addEventListener('load', e => {
            let script2 = document.createElement('script');
            script2.id = 'globe';
            script2.src = 'news/css_globe_PerspectiveTransform.js';
            document.body.append(script2);
            script2.addEventListener('load', e => {
              let script3 = document.createElement('script');
              script3.id = 'worldScript';
              script3.src = 'news/script.js';
              document.body.append(script3);
              script3.addEventListener('load', e => {
                $("div.dg").hide()
              });
            })
          })
        })
      }else{
        this.drawBanner();
      }
      // 监听窗口大小改变
      $(window).resize(() => {
        if (this.bannerManage.type === "video") {
          // 视频背景图改变
          let clientWidth = document.body.clientWidth
          $(".video-model video").css("width", clientWidth + "px")
          $(".video-model video").css("marginLeft", -clientWidth / 2 + "px")
        }
      });
      // 解决ie11 视频banner不全屏问题 因为object-fill不被支持
      let videoElement = document.querySelector('#video');
      objectFitPolyfill(videoElement);
    })

    // 获取列表
    this.ListMethods().getList(1, this.lists.pages.size, this.lists.query)
  },
  methods: {
    // 渲染banner
    drawBanner() {
      console.log(this.newsManage.bannerManage.type)
      if (this.newsManage.bannerManage.type === "video") {
        $(".video-model video").attr("src", this.newsManage.bannerManage.banner_pc_video)
      } else {
        $(".image-model").css("background-image", `url(${this.newsManage.bannerManage.banner_pc_img})`)
      }
    },
    // 列表Methods
    ListMethods() {
      let $this = this
      return {
        // 获取列表
        async getList(page, size, query) {
          $this.lists.loading = true;
          query=Object.assign({
            owner:"zyhd"
          },query);
          [$this.lists.list, $this.lists.pages] = await OfficialWebNewsModel.getPageList(page - 1, size, "sortOrder,desc", query)
          $this.lists.loading = false
        },
        // 分页-改变页码
        async pageChange(page) {
          this.getList(page, $this.lists.pages.size, $this.lists.query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(size) {
          this.getList($this.lists.pages.page, size, $this.lists.query)
        },
        // 点击某个新闻
        clickOneNews(news) {
          window.location.href="/newsView?id="+news.officialWebNewsId;
        }
      }
    },
  }
}
</script>
<style scoped lang="less">
// 地球

.bg-world{
  .world .text{
    text-align: center;
    font-size: 70px;
    position: absolute;
    width: 300px;
    height: 250px;
    bottom: 100px;
    left: 15%;
    color: #fff;
    margin:0 auto;
    font-weight: bold;
    .english{
      font-size: 44px;
      margin-top: 10px;
    }
  }
}

// 顶部
.news-header {
  margin-bottom: 50px;

  .video-model {
    width: 100%;
    position: relative;
    height: 600px;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 600px;
      object-fit: fill;
    }
  }

  // 图片模式
  .image-model {
    z-index: 0;
    width: 100%;
    height: 600px;
    color: transparent;
    background-size: cover;
    background-position: 50% 50%;
    opacity: 1;
    -webkit-backface-visibility: hidden;
    //background-image: url("http://resouce.cdzyhd.com/ece7c357-2d8c-421c-839c-fbfb804a7ca3.png");
  }
}


// 新闻列表
.news-list {
  width: 1280px;
  margin: 0 auto;

  .li {
    border-bottom: 1px solid #cecece;
    margin-bottom: 40px;
    padding-bottom: 50px;
    cursor: pointer;

    .li-left {
      width: 85%;
      align-items: flex-start;

      img.head-img {
        width: 200px;
        height: 150px;
        margin-right: 40px;
        object-fit: cover;
      }

      .info {
        margin-right: 50px;

        .title {
          font-size: 18px;
          font-weight: 700;
          text-align: left;
          line-height: 22px;
          width: 739px;
          margin-bottom: 40px;
          margin-top: 0px
        }

        .description {
          width: 739px;
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          line-height: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
    }

    .li-right {
      width: 15%;
      border-left: 1px solid #cecece;
      color: #8a8989;

      .day {
        font-size: 60px;
        font-weight: 700;
      }

      .year-month {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

// 新闻分页
.news-page-tool {
  width: 1280px;
  margin: 0 auto;
  text-align: right;
  padding: 50px 0px;
}
</style>
<style>
#header-container {
  background-color: rgba(0, 0, 0, 0.4);
}

#header-container .navs a {
  color: #fff !important;
  opacity: 0.6;
}

#header-container .navs a:hover {
  opacity: 1;
}

#header-container img.black {
  display: none;
}

#header-container .product-navs .list {
  display: none;
}
</style>
